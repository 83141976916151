import React from "react"

import Img from "gatsby-image"

import { Link } from "gatsby"

const Book = (props) => {

    const { frontmatter } = props.book

    const slug = props.book.fields.slug


    return (
            <div className="flex m-5">
                <div className="book-cover">
                    <Img fluid={frontmatter.image.childImageSharp.fluid} alt={frontmatter.title} />
                </div>
                <div className="flex flex-col pl-6 mt-2">
                    <p><span className="red">Title:</span> {frontmatter.title}</p>
                    <p><span className="red">Author:</span> {frontmatter.author}</p>

                    <p className="red">Progress:</p>
                    <div className="mt-0">
                        <div className="progress-bar-background">
                            <span style={{width: frontmatter.progress}} className="progress-bar" />
                        </div>
                        {frontmatter.progress}
                    </div>

                    <div className="flex flex-col">
                        <a href={frontmatter.amazon} className="green-button p-2 flex justify-center mb-2">Buy The Book</a>
                        <Link to={slug} className="green-button p-2 flex justify-center">Read My Notes</Link>
                    </div>
                </div>
            </div>
    )

}


export default Book
